// ==========================================================================
// Grid display
// ==========================================================================

.is-displayed-mobile {
  @include mq(small) {
    display: block;
    @at-root (with: media) {
      .is-hidden-mobile {
	display: none;
      }
    }
  }
  @include mq(medium) {
    display: none;
  }
}



.is__visible {
    right: 0;
}
