// ==========================================================================
// Icons
// ==========================================================================

// Logo

.site-logo {
  @include img-replace("logo.gif", 152px, 68px, block);

}
.site-logo-trial {
  color: $white;

}
// UI

.icn {

  // Nav Toggle
  @include m(nav-toggle) {
    @extend %icn--ui;
    width: 25px;
    height: 17px;
    border-top: solid 3px palette(grey);
    border-bottom: solid 3px palette(grey);
    position: relative;
    @include p-el(before, 25px, 3px) {
      background: palette(grey, light);
      top: 4px;
    }
  }

  // Close
  @include m(close) {
    @extend %icn--ui;
    @include img-replace("./src/img/icn-close.svg", 20px, 20px);
    right: 4%;
    top: 4%;
  }
}
.license {
  @include e(icon){
    // width: 60px;
    // height: 60px;
    width: auto;
    height: 60px;
    border-radius: 5px;
    box-shadow: 1px 2px 4px #dddddd;
  }
}
.special{
  position: absolute;
  top: 25px;
  right: 32px;
  @include e(icon){
    width: 25px;
    height: 25px;
  }
}
