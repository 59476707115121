// ==========================================================================
// Global Config
// ==========================================================================

// Font Stacks

$font-url--google        : 'https://fonts.googleapis.com/css?family=Lato:400,700|Source+Sans+Pro:200,300,400';
$font-family--primary    : 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-family--secondary  : 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;

// Font Weights

$font-weight--thin       : 200;
$font-weight--light      : 300;
$font-weight--medium     : 400;
$font-weight--bold       : 700;

// Descriptive Base Colors

$white : #fff;
$black : #0b0b0b;
$grey  : #4c5a61;
$red   : #e54b4b;

$imi            : #009edf;
$emerald        : #5cb85c;
$sunglo         : #e67478;
$coral          : #ff784f;
$purple-majesty : #9279c3;
$scooter        : #39add1;
$riverbed       : #4c5a61;

// Color Usage

$color-primary   : $imi;
$color-secondary : $grey;
$color-tertiary  : $riverbed;
$color-accent    : $emerald;
$color-shadow    : rgba($black, .2);

// Color Palette Modifiers

$color-palettes: (
grey: (
xx-light : lighten($grey, 43%),
x-light  : lighten($grey, 35%),
light    : lighten($grey, 12%),
base     : $grey,
dark     : darken($grey, 8%),
x-dark   : darken($grey, 16%)
),
black: (
light    : lighten($black, 10%),
base     : $black,
dark     : darken($black, 10%)
),
imi: (
xx-light : lighten($imi, 43%),
x-light  : lighten($imi, 35%),
light    : lighten($imi, 12%),
base     : $imi,
dark     : darken($imi, 8%),
x-dark   : darken($imi, 16%)
)
);

// UI Colors

$ui-colors: (
default : $imi,
success : $emerald,
error   : $sunglo,
warning : $coral,
info    : $purple-majesty
);

// Text

$base__font-size : 16px;
$base__line      : 24px;
$letter-space    : 1px;

// Border radius

$br--default    : 0em;
$br--round      : 50%;

// Transitions

$trns-duration  : .3s;

// Path to Assets

$path--rel      : "/img";

// Grid

$g-col-width    : 65px !default;
$g-gutter-width : 20px !default;
$g-col-count    : 12 !default;
$g-cont-max-w   : /*1280px !default*/100%;

// Breakpoints

$brkpoint--sm    : 1px;
$brkpoint--md    : 768px;
$brkpoint--lg    : 1100px;

$fa-font-path:        "~@fortawesome/fontawesome-free/webfonts";