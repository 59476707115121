// ==========================================================================
// Functions
// ==========================================================================

// Import if Google Fonts URL is defined

@if variable-exists(font-url--google) {
  @import url($font-url--google);
}

// Calculate em values

@function em($target, $context: $base__font-size) {
  @return ($target / $context) * 1em;
}

// Call the color palette modifiers in color values

@function palette($palette, $shade: 'base') {
  @return map-get(map-get($color-palettes, $palette), $shade);
}

// Set the "context" width for the grid

@function g-context($g-col-width, $g-col-count ,$g-gutter-width) {
  $g-context: ($g-col-width * $g-col-count) + ($g-gutter-width * ($g-col-count - 1));
  @return $g-context;
}
