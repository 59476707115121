
// ==========================================================================
// Bootstrap 
// ==========================================================================
//@import "~bootstrap/dist/css/bootstrap.min.css";

@import "~semantic-ui-css/semantic.min.css";
@import "semantic-ui-css-personalized.scss";
// ==========================================================================
// Utilities
// ==========================================================================
@import "utilities/index";

// ==========================================================================
// Base Styles
// ==========================================================================
@import "base/index";

// ==========================================================================
// Layout Styles
// ==========================================================================
@import "layout/index";

// ==========================================================================
// Modules
// ==========================================================================
@import "modules/index";

// ==========================================================================
// States
// ==========================================================================
@import "states/index";

// ==========================================================================
// FontAwesome
// ==========================================================================
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";
.loading {
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 99999999;
}
div.ui.grid{
    width: 100%;
    margin: 0px;
}

.loading .bar {
    position: relative;
    height: 2px;
    width: 500px;
    margin: 0 auto;
    background: #fff;
    margin-top: 150px;
}

.loading .circle {
    position: absolute;
    top: -30px;
    margin-left: -30px;
    height: 60px;
    width: 60px;
    left: 0;
    background: #fff;
    border-radius: 30%;
    -webkit-animation: move 5s infinite;
}

.align.left{
    text-align: left;
}

/* Chrome, Safari, Edge, Opera */
.no-spin > input::-webkit-outer-spin-button,
.no-spin > input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
}

/* Firefox */
.no-spin > input[type=number] {
    -moz-appearance: textfield !important;
}

.ui.table thead th.sortable {
    cursor: pointer;
}

.loading p {
    position: absolute;
    top: -35px;
    right: -85px;
    text-transform: uppercase;
    color: #000;
    font-family: helvetica, sans-serif;
    font-weight: bold;
}

.ui.selection.dropdown {    
    padding: 10px;
    min-height: 2em;
}
.ui.selection.dropdown > .dropdown.icon{
    top: 10px;
}

.ui.selection.active.dropdown > .menu{
    position: relative;
    left: 1px;
    top: 10px;
}

.ui.selection.active.dropdown > .menu {
    position: absolute !important;
}

@-webkit-keyframes move {
    0% {
        left: 0;
    }

    50% {
        left: 100%;
        -webkit-transform: rotate(450deg);
        width: 150px;
        height: 150px;
    }

    75% {
        left: 100%;
        -webkit-transform: rotate(450deg);
        width: 150px;
        height: 150px;
    }

    100% {
        right: 100%;
    }
}
