// ==========================================================================
// Placeholders
// ==========================================================================

// Nav Items

%nav-item-horz {
  @include mq(medium) {
    display: block;
    margin: em(32px) em(10px);
  }
}
%nav-item-link {
  font-weight: $font-weight--light;
  text-align: center;
  font-size: em(18px);
  display: block;
  padding: em(8px, 20px);
}
%nav-item-on {
  color: $white;
  border-color: $color-primary;
}

// Headlines

%hdln-prim {
  color: $imi;
  font-weight: $font-weight--medium;
  font-family: $font-family--primary;
  font-size: em(30px);
  line-height: (46px/30px);
  margin-top: em(10px);
}

%hdln-sec {
  color: $white;
  letter-spacing: $letter-space;
  font-weight: $font-weight--thin;
  font-size: em(26px);
  font-family: $font-family--secondary;
}

%hdln-ter {
  color: $grey;
  letter-spacing: $letter-space;
  font-weight: $font-weight--medium;
  font-size: em(22px);
  font-family: $font-family--primary;
}

// Buttons

%btn {
  font-family: $font-family--primary;
  border: 0;
  border-radius : $br--default;
  color: $white;
  font-size : em(20px);
  letter-spacing: 1px;
  font-weight: $font-weight--medium;
  transition: opacity $trns-duration;
  display: block;
  &:hover {
    opacity: .75;
  }
  &:active {
    opacity: initial;
  }
}

// Menu link

%menu-link {
  display: block;
  padding-top: em(16px);
  padding-bottom: em(16px);
  color: $white;
  text-align: center;
  text-shadow: 0 1px $color-shadow;
  font-size: em(18px);
}

// Icons

%icn--ui {
  line-height: 0;
  cursor: pointer;
}
