$red: rgb(255, 128, 25);
$redBorder: #FF6E00;
$positive: #21ba45;
$im-primary-color: rgba(0, 158, 223, 0.85);
$im-primary-border-color: #bbebff;
$letter-space: 1px;
$font-weight--medium: 400;
$font-family--primary: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-family--secondary: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;

.ui.dimmer {
  background-color: $im-primary-color;
}

.ui.popup.error,
.ui.popup.error::before {
  background: $red;
  color: white;
  border: $red;
}

div.im_text_field > div.dropdown > i.dropdown{
  color: $im-primary-color
}

div.im_text_field div.ui.dropdown,
div.im_text_field div.ui.dropdown:hover,
div.im_text_field div.ui.dropdown:focus,
div.ui.input.im_text_field input[type="password"],
div.im_text_field div.input input[type="text"],
div.im_text_field div.input input[type="text"]:hover,
div.im_text_field div.input input[type="text"]:focus,
div.im_text_field div.input input[type="password"],
div.im_text_field div.input input[type="password"]:hover,
div.im_text_field div.input input[type="password"]:focus,
div.im_text_field div.input input[type="email"],
div.im_text_field div.input input[type="email"]:hover,
div.im_text_field div.input input[type="email"]:focus,
div.im_text_field div.input input[type="Number"],
div.im_text_field div.input input[type="Number"]:hover,
div.im_text_field div.input input[type="Number"]:focus {
  line-height: 1.15;
  background: #fff;
  padding: 10px;
  font-size: 1.125em;
  border-radius: 0em;
  padding-bottom: 15.268px;
  padding-top: 15.268px;
  padding-left: 15.268px;
  padding-right: 15.268px;
  font-weight: 300;
  outline: none;
  min-height: 50px;
  letter-spacing: 1px;
  border: 1px solid #bbebff;
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

div.im_dropdown_button_field div.ui.dropdown,
div.im_dropdown_button_field div.ui.dropdown:focus {
    background: $red;
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid $red;
    border-radius: 0px;
}

div.im_dropdown_button_field div.ui.dropdown:hover {
    background: $red;
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid $redBorder;
}

.ui.popup.red, 
.ui.right.center.popup.red:before {
  color: white;
  background: $red;
}

.im_text_field.labeled > div.ui.label{
  background: $im-primary-color;
  color: #e1e1e1;
}

.ui-alerts.bottom-right{
    position: fixed;
    left: 0px;
    bottom: 0px;
    min-width: 400px;
    width: 400px;
    max-width: 400px;
}
div.ui.selection.dropdown,
div.ui.selection.dropdown:focus,
div.ui.selection.dropdown:hover,
div.ui.dropdown,
div.ui.dropdown:focus,
div.ui.dropdown:hover,
div.im_text_field.labeled.ui.input > input,
div.im_text_field.labeled.ui.input > input:focus,
div.im_text_field.labeled.ui.input > input:hover,
div.ui.fluid.input.im_text_field.im_text_field > input,
div.ui.form-input > input,
div.ui.form-input > input:focus,
div.ui.form-input > input:hover
div.ui.form-input > input,
div.ui.form-input > input:focus,
div.ui.form-input > input:hover {
  border-color: $im-primary-border-color;
  border-radius: 0px;
  font-size: 1.125em;
}
div.fluid.ui.dropdown > i.dropdown.icon {
  color: $im-primary-border-color;
}
div.fluid.ui.dropdown:focus > i.dropdown.icon,
div.fluid.ui.dropdown:hover > i.dropdown.icon {
  color: $im-primary-color;
}
.ui.primary.button,
.ui.primary.button:hover,
.ui.primary.button:focus {
    /*background-color: rgb(0, 158, 223);*/
    background-color: $red;
    border-radius: 0px;
    border: 1px solid $red;
    margin-bottom: 0;
    letter-spacing: $letter-space;
    font-weight: $font-weight--medium;
    font-family: $font-family--secondary;
}

.ui.secondary.button,
.ui.secondary.button:hover {
    background-color: white;
    border-radius: 0px;
    border: 1px solid $red;
    color: $red;
}

.div.ui.primary.button {
    /*background-color: rgb(0, 158, 223);*/
    background-color: $red;
    border-radius: 0px;
    border-color: $red;
}

div.ui.negative.message {
    border-radius: 0px;
}

.ui.modal > .header,
.ui.modal > .actions {
  border-color: rgb(0, 158, 223);
}
.ui.modal.success > .content > p:before {
  content: "\f00c";
  font-family: Icons;
  color: $positive;
}
.ui.modal.success > .header > p:before {
  content: "Success";
  font-family: Icons;
  color: $positive;
}
.ui.modal.error > .content > p:before {
  content: "\f00d";
  font-family: Icons;
  color: $red;
}
.ui.modal.error > .header > p:before {
  content: "Error";
  font-family: Icons;
  color: $red;
}
.ui-alerts{
    z-index: 2147483647;
}

.ui-alerts.bottom-right{
    position: fixed;
    left: 0px;
    bottom: 0px;
    min-width: 400px;
    width: 400px;
    max-width: 400px;
}