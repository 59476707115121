// ==========================================================================
// Headlines
// ==========================================================================

.headline {

	&-primary {
		@extend %hdln-prim;
		margin-bottom: em(70px, 42px);

		@include m(grouped) {
			@extend %hdln-prim;
			margin-bottom: 0;
			@include m(inverse) {
				@extend %hdln-prim;
				color: $riverbed;
			}
		}
		@include m(centered) {
			@extend %center-align;
	 		margin-bottom: em(32px, 24px);
			@include m(inverse) {
				@extend %center-align;
				@extend %hdln-prim;
				color: $riverbed;
			}
		}
	}
	&-search {
    color: #677a83;
    font-weight: $font-weight--light;
    font-family: $font-family--primary;
    font-size: em(20px);
    line-height: (46px/30px);
    margin-top: em(10px);
	}

 	&-secondary {
		@extend %hdln-sec;
		margin-bottom: em(22px, 24px);

		@include m(grouped) {
			@extend %hdln-sec;
			margin-top: em(10px, 24px);
	 		margin-bottom: em(54px, 24px);
		}
		@include m(centered) {
			@extend %center-align;
	 		margin-bottom: em(32px, 24px);
			@include m(inverse) {
				@extend %center-align;
				@extend %hdln-sec;
				color: $riverbed;
			}
		}
	}
 	&-tertiary {
		@extend %hdln-ter;
		margin-bottom: em(22px, 24px);

		@include m(grouped) {
			@extend %hdln-ter;
			margin-top: em(10px, 24px);
	 		margin-bottom: em(54px, 24px);
		}
		@include m(inverse) {
			@extend %hdln-ter;
			margin-top: em(10px, 24px);
			margin-bottom: em(24px, 24px);
			color: $white;
			@include m(grouped) {
				@extend %hdln-ter;
				margin-top: em(18px, 24px);
				margin-bottom: 0;
				color: $white;
			}
		}

		@include m(centered) {
			@extend %center-align;
			@extend %hdln-ter;
			margin-top: em(10px, 24px);
	 		margin-bottom: em(24px, 24px);
			@include m(inverse) {
				@extend %center-align;
				@extend %hdln-ter;
				margin-top: em(10px, 24px);
		 		margin-bottom: em(24px, 24px);
				color: $white;
			}
		}
	}

}
