.link {
    color: $black;
    text-decoration: none;
    font-weight: 200;
    &:hover {
        color: $black;
        text-decoration: underline;
    }
    @include e(back){
        position: absolute;
        top: 0px;
        left: 0px;
        color: $black;
        text-decoration: none;
        font-weight: 200;
        &:hover {
            color: $black;
            text-decoration: underline;
        }
	}
}
.loginForgotPasswdLink, .loginForgotPasswdLink:hover {
    color: #4c5a61;
    font-size: 14px;
    font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
    line-height: 1.5;
}