// ==========================================================================
// Panels
// ==========================================================================

.panel {
  @extend %panel-default;

  @include e(inverse) {
    background-color: $white;
    border-radius: $br--default;
    padding: em(32px);
    box-shadow: 1px 1px 4px 1px rgba(76,90,97,0.3);
    margin-bottom: 4em;
    @include m(padded) {
      background-color: $white;
      border-radius: $br--default;
      padding: em(32px);
      box-shadow: 1px 1px 4px 1px rgba(76,90,97,0.3);
      min-height: 250px;
    }
  }
  @include e(text){
    padding: 0px em(24px) em(20px) em(24px);
    margin-bottom: 0;
    font-size: em(12px);
    letter-spacing: $letter-space;
    font-weight: $font-weight--medium;
    font-family: $font-family--secondary;
    color: palette(grey, light);
    @include m(title){
        margin-bottom: 0;
        font-size: em(18px);
        letter-spacing: $letter-space;
        font-weight: $font-weight--bold;
        font-family: $font-family--secondary;
        color: palette(grey, light);
    }

    @include m(inverse){
      margin-bottom: 0;
      font-size: em(14px);
      letter-spacing: $letter-space;
      font-weight: $font-weight--medium;
      font-family: $font-family--secondary;
      color: $white;
    }
    @include m(center){
      margin: 0 0 em(30px, 18px);
      font-size: em(16px);
      letter-spacing: $letter-space;
      font-weight: $font-weight--light;
      font-family: $font-family--secondary;
      color: $grey;
      text-align: center;
    }

    @include m(centered){
      margin-bottom: 0;
      font-size: em(16px);
      letter-spacing: $letter-space;
      font-weight: $font-weight--medium;
      font-family: $font-family--secondary;
      color: $grey;
      text-align: center;
    }
  }
  @include e(icon){
    width: 100px;
    height: 100px;
    margin-bottom: 1em;
    margin-top: 1em;
    @include m(video){
      width: 100px;
      height: 100px;
      margin: 1em;
    }
    // --primary-color: $color-primary;
    // --secondary-color: $color-secondary;
    // --tertiary-color: $color-accent;
    // --quaternary-color: $color-link;
  }

  @include m(centered) {
    @extend %panel-default;
    @extend %center-align;
  }
  @include m(padded) {
    @extend %panel-padding;
    @include m(centered) {
      @extend %center-align;
      @extend %panel-padding;
    }
  }
}
