
.accordion__container {
  border-top: 1px solid #ddd;
  padding-top: 15px;
  padding-left: 15px;
  padding-bottom: 15px;
}
.accordion__title {
  padding-left: 15px;
}

.alert-danger {
    color: #ffffff;
    background-color: $red;
    border-color: $red;
}
