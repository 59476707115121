// ==========================================================================
// Grid Container
// ==========================================================================

.grid {
	@extend %clearfix;
	@extend .centered;
	width: 95%;

	// Make nested grid container 100%
	[class*="grid__col--"] > & {
		width: 100%;
	}

	// Set max-width for grid container
	@include mq(large) {
		max-width: $g-cont-max-w;
	}
}