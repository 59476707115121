// ==========================================================================
// Menu
// ==========================================================================

.menu {
	float: right;
	margin-top: em(20px);
	margin-left: em(32px);
	cursor: pointer;
	// @include e(link) {
	// 	@extend %menu-link;
	// 	border-bottom: solid 1px rgba($white,.3);
	// 	@include m(end) {
	// 		@extend %menu-link;
	// 	}
	// }
	@include e(icon){
		width: 35px;
		height: 35px;
		fill: #fff;
	}
}
