.ReactTable .rt-thead .rt-resizable-header {
  overflow: hidden;
  outline:none;
  border-top: none;
}
.ReactTable .rt-thead .rt-th.-sort-asc, .ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 3px 0 0 rgb(0, 158, 224);
  overflow: hidden;

}
.ReactTable .rt-thead .rt-th.-sort-desc, .ReactTable .rt-thead .rt-td.-sort-desc{
  box-shadow: inset 0 -3px 0 0 rgb(0, 158, 224);
  overflow: hidden;
}
.ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td {
  padding: 15px 5px;
  border-right: none;
  text-align: left;
}
.ReactTable .rt-th, .ReactTable .rt-td {
  padding: 15px 5px;
}
.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: solid 1px rgb(221, 221, 221);
}
.ReactTable .rt-thead.-filters {
  border-bottom: none;
}
.ReactTable .rt-thead.-header {
  box-shadow: none;
  border-bottom: 1px solid #ddd;
}
.rt-resizable-header-content {
  margin-bottom: 0;
  font-size: 1.125em;
  letter-spacing: 1px;
  font-weight: 700;
  font-family: "Source Sans Pro","Helvetica Neue",Helvetica,Arial,sans-serif;
  color: #677a83;
}
.ReactTable {
  border-color: transparent;
}
.ReactTable .-pagination .-btn {
  border: 0;
  border-radius: .2em;
  padding: 13px 16px;
  font-size: 1em;
  color: #fff;
  background: rgb(0, 158, 224);
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 200;
}
.ReactTable .-pagination .-btn:not([disabled]):hover {
  background: rgb(0, 158, 224);
  opacity: .75;
  color: #fff;
}

.ReactTable .-pagination {
  padding: 10px 0px;
  box-shadow: none;
  border-top: solid 1px rgb(221, 221, 221);
}
.ReactTable .rt-thead.-filters input {
  background: #fff;
  padding: 10px;
  font-size: 0.875em;
  border-radius: .2em;
  font-weight: 300;
  outline: none;
  letter-spacing: 1px;
  border: 1px solid #bbebff;
  font-family: "Source Sans Pro","Helvetica Neue",Helvetica,Arial,sans-serif;
}
.ReactTable .rt-thead.-filters select {
  background: #fff;
  padding: 10px;
  font-size: 0.875em;
  border-radius: .2em;
  font-weight: 300;
  outline: none;
  letter-spacing: 1px;
  border: 1px solid #bbebff;
  font-family: "Source Sans Pro","Helvetica Neue",Helvetica,Arial,sans-serif;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  background-image:
    linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;
}
