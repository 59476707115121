﻿// ==========================================================================
// Check boxes
// ==========================================================================

.checkbox-button {
    cursor: pointer;
}

input[type=checkbox] {
    box-sizing: border-box;
    padding: 0;
}

input {
    font-size: 1rem;
    line-height: 1.5;
    padding: 11px 23px;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 0;
    outline: 0;
    background-color: transparent;
}

.checkbox-button__input {
    opacity: 0;
    position: absolute;
}

.checkbox-button__control {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 2px;
    vertical-align: middle;
    background-color: inherit;
    color: #017b5f;
    border: 2px solid #666;
}

.checkbox-button__input:checked + .checkbox-button__control:after {
    content: "";
    display: block;
    position: absolute;
    left: 5px;
    top: 0px;
    width: 7px;
    height: 14px;
    border: solid $im-primary-color;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

.checkbox-button__input:checked + .checkbox-button__control {
}

.checkbox-button__control {
    transform: scale(0.75);
}
