// ==========================================================================
// Buttons
// ==========================================================================

.btn {
  @include ui-colors($ui-colors);
  @include e(primary){
    font-family: $font-family--primary;
    border: 0;
    border-radius : $br--default;
    background-color: $color-primary;
    color: $white;
    font-size : em(14px);
    line-height: (46px/30px);
    margin-right: em(5px);
    letter-spacing: 1px;
    font-weight: $font-weight--thin;
    transition: opacity $trns-duration;
    display: block;
    min-width: 110px;
    padding: 10px 16px;
    @include m(inverse){
      background-color: $color-secondary;
      font-family: $font-family--primary;
      border: 0;
      border-radius : $br--default;
      color: $white;
      font-size : em(14px);
      line-height: (46px/30px);
      letter-spacing: 1px;
      font-weight: $font-weight--thin;
      transition: opacity $trns-duration;
      display: block;
      min-width: 110px;
      padding: 10px 16px;
      &:hover {
        opacity: .75;
      }
      &:active {
        opacity: initial;
      }
    }
    @include m(red){
      background-color: $red;
      font-family: $font-family--primary;
      border: 0;
      border-radius : $br--default;
      color: $white;
      font-size : em(14px);
      line-height: (46px/30px);
      letter-spacing: 1px;
      font-weight: $font-weight--thin;
      transition: opacity $trns-duration;
      display: block;
      min-width: 110px;
      padding: 10px 16px;
      &:hover {
        opacity: .75;
      }
      &:active {
        opacity: initial;
      }
    }
    &:hover {
      opacity: .75;
    }
    &:active {
      opacity: initial;
    }
  }
}


[class^="btn--"] {
  @extend %btn;
  @extend %center-align;
  margin-bottom: em(20px, 14px);
  @include mq(small) {
    width: 100%;
  }
  @include mq(medium) {
    @include doubly(20px);
    width: auto;
    display: inline-block;
  }
}
