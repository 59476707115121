.text{
  margin-bottom: 0;
  font-size: em(14px);
  letter-spacing: $letter-space;
  font-weight: $font-weight--medium;
  font-family: $font-family--secondary;
  /*color: palette(black, light);*/
}
div.main-page.dropdown > div.menu > div.item > span.text
div.ui.main-page.dropdown:not(.button) > div.default.text,
div.dropdown.main-page > div.text,
div.ui.scrolling.dropdown .menu .item.item.item > span.text{
  font-weight: bold;
  color: black !important;
}
