// ==========================================================================
// Helpers
// ==========================================================================

// Clearfix

%clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}
.overlay {
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}
.padding__extra{
    padding: 3em!important;
}
.blue-phrase{
  color: $imi!important;
}
.relative {
  position: relative;
}

.astreik{
  color:$red;
  margin-right: 5px;
}
.validation-text{
  display:relative;
  font-size: 14px;
  color:$red;
  padding:5px;
  font-style: italic;
}
.left{
  float: left;
}
.right{
  float: right;
}
.text-right{
  text-align: right;
}
body#app-main.push__left {
  left: -20%;
}
// Screen reader text

.srt {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

// Center alignment

%center-align {
  text-align: center;
}
.center {
  text-align: center;
}

// For pseudo-elements

%pseudos {
  display: block;
  content: '';
  position: absolute;
}
.top{
  padding-top: 4em;
}
.bottom{
  padding-bottom: 4em;
}
// Center elements

.centered {
  float: none;
  margin-left: auto;
  margin-right: auto;
}

.underline{
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: $grey;
}
.white{
  color: $white;
  font-weight: $font-weight--thin;
  border-bottom-color: $white;
}
.red{
  color: $red;
  font-weight: $font-weight--bold;
}
.padded{
  margin-bottom: 5em;
}
.no__padding{
  padding-left: 0;
  padding-right: 0;
}
.margin__right{
  margin-right: 7px;
}
.margin__top{
  margin-top: 11px;
}

.hidden{
  display: none;
}

body, .footer__link, .footer__link--social, .logo, .nav, .nav__container, .nav__link, .card__info, .card__description, .card__link--social, .form__label, .form__input, .form__input--textarea, .form__button {
  transition: all 0.2s cubic-bezier(0, 0.65, 0.63, 0.74);
}



// Loading animation

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 11px;
}
.lds-ellipsis div {
  position: absolute;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}

.loginLeftLink{
    display:inline;
}

.loginRightLink{
    display:inline;
    float:right;
}

.loginCheckBoxStyle{
    display:inline;
    vertical-align:middle;
    font-size:14px;
}

.LoginTitleBottomPadding{
    padding-bottom:15px;
}

