// ==========================================================================
// Collapsed
// ==========================================================================

.is-collapsed-mobile {
	@include mq(small) {
		visibility: collapse;
		padding: 0;
		height: 0;
		margin: 0;
		line-height: 0;
	}
}