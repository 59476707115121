// ==========================================================================
// Form Elements
// ==========================================================================

.form {
	@include e(label) {
		@include m(hidden) {
			@extend .srt;
		}
		display: block;
		margin-bottom: em(10px);
		font-weight: $font-weight--medium;
		padding-left: em(10px);
		letter-spacing: $letter-space;
		font-family: $font-family--secondary;
		color: $grey;
	}

	@include e(text){
		padding-top: 1rem;
    padding-bottom: 1rem;
	}

	@include e(group){
		display: block;
	}

	@include e(input) {
    width: 100%;
		font-size : em(18px);
		margin-bottom: em(20px, 18px);
		border: 1px solid palette(imi, xx-light);
		border-radius: $br--default;
		color: #677a83;
		font-weight: $font-weight--light;
		font-family: $font-family--secondary;
		letter-spacing: $letter-space;
    @include m(small){
      font-size : em(18px);
  		// margin-bottom: em(20px, 18px);
  		border: 1px solid palette(imi, xx-light);
  		border-radius: $br--default;
  		color: #677a83;
  		font-weight: $font-weight--light;
  		font-family: $font-family--secondary;
  		letter-spacing: $letter-space;
      padding: 10px;
      width: 100%;
      background-color: transparent;
    }
		&:focus {
			border-color: palette(imi, light);
		}
	}

	@include e(select) {
    font-size: 1.125em;
    border: 1px solid #bbebff;
    border-radius: $br--default;
    color: #677a83;
		font-weight: $font-weight--light;
		font-family: $font-family--secondary;
    letter-spacing: 1px;
    width: 100%;
    background-color: transparent;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: 15px;
    height: 42px;
    max-width: 515px;
}
	@include e(btn) {
		@extend %btn;
		padding: 15px 30px;
		background: $color-primary;
		box-shadow: 1px 1px 4px 1px rgba(76,90,97,0.3);
		margin-bottom: 1em;
    width: 100%;
		@include m(icon) {
			@extend %btn;
			padding: 15px 30px;
			background: $color-primary;
			box-shadow: 1px 1px 4px 1px rgba(76,90,97,0.3);
			margin-bottom: 1em;
			width: 100%;
			position: relative;

		}
		@include m(outline) {
			@extend %btn;
			padding: 15px 30px;
			background: transparent;
			margin-bottom: 1em;
			width: 100%;
			color: #009edf!important;
			text-align: center;
			border: 1px solid #009edf;
		}
	}
	@include e(icon){
		width: 35px;
		height: 35px;
		position: absolute;
		fill: #fff;
		top: 6px;
		left: 68px;
	}
}
