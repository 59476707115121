
.badge {
  padding: 5px 9px;
  margin-bottom: 5px;
  margin-left: 15px;
  background-color: $grey;
  cursor: pointer;
  @include m(red){
    display: inline-block;
    color: $white;
    border-radius: 0px;
    font-size: 16px;
    font-weight: $font-weight--bold;
    padding: 5px 9px;
    margin-left: 15px;
    cursor: pointer;
    background-color: $red;
  }
  @include m(grey){
    display: inline-block;
    color: $white;
    border-radius: 0px;
    font-size: 16px;
    font-weight: $font-weight--bold;
    padding: 5px 9px;
    margin-left: 15px;
    cursor: pointer;
    background-color: $grey;
  }
}
