// ==========================================================================
// Navbar
// ==========================================================================

.navbar {	
	position: relative;
	@include mq(medium) { 
		margin-top: em(54px);
		margin-bottom: 0;
	}
}