// ==========================================================================
// Nav
// ==========================================================================

.nav {
  background-color: $riverbed;
  margin-bottom: em(30px);
  position: fixed;
  border-left: 1px solid palette(grey, light);
  height: 100%;
  width: 20%;
  top: 0;
  right: -20%;

  @include e(item) {
    @extend %nav-item-horz;

    @include m(current) {
      @extend %nav-item-horz;
      a {
        @extend %nav-item-link;
        @extend %nav-item-on;
      }
    }
    a {
      @extend %nav-item-link;
      color: $white;
      &:hover {
        @extend %nav-item-on;
      }
    }
  }
}
