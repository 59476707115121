// ==========================================================================
// Placeholders
// ==========================================================================

%panel-default {
	padding-top: em(30px);
	@include mq(medium) {
		padding-bottom: em(20px);
	}
}

%panel-padding {
	padding-top: em(16px);
	@include mq(medium) {
		padding-top: em(32px);
		padding-bottom: em(16px);
	}
}
